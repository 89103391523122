<script>
import { Line, mixins } from 'vue-chartjs'

export default {
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: [
        'chartData'
    ],

    watch: {

        'params.articles': {
            handler() {
                this.renderChart({
                labels: this.chartData.labels,
                datasets: [
                    {
                    label: 'Umsatzverlauf',
                    backgroundColor: 'transparent',
                    borderColor: '#007bff',
                    pointBackgroundColor: '#007bfd',
                    data: this.chartData.data,
                    }
                ]
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                        text: "Kundenumsatz"
                    }
                })
            },
            deep: true
        },

    },

    mounted () {
        // Overwriting base render method with actual data.
        this.renderChart({
        labels: this.chartData.labels,
        datasets: [
            {
            label: 'Umsatzverlauf',
            backgroundColor: 'transparent',
            borderColor: '#007bff',
            pointBackgroundColor: '#007bfd',
            data: this.chartData.data,
            }
        ]
        },
        {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
                text: "Kundenumsatz"
            }
        })
    }
}
</script>